import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Contacts() {
    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-r from-slate-100 to-slate-200">
            <Navbar />

            <main className="w-full p-8 flex flex-col items-center flex-grow">
                <h1 className="text-6xl font-light text-tropaz-700 font-oswald uppercase mb-12 p-4">
                    Contact Us
                </h1>

                <section className="w-full max-w-4xl p-6 bg-white shadow-lg rounded-lg border-l-4 mb-12">
                    <h2 className="text-4xl font-semibold text-center text-tropaz-700 mb-8">Stay Connected</h2>

                    <div className="flex flex-wrap justify-between">
                        {/* Instagram Section */}
                        <div className="flex flex-col items-center w-full md:w-1/2 px-4 mb-8 md:mb-0">
                            <i className="bx bxl-instagram text-5xl mb-4"></i>
                            <h3 className="text-2xl font-medium mb-4">Follow us on Instagram</h3>
                            <a
                                href="https://www.instagram.com/whartonhs.fbla"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-poppins w-56 h-12 rounded-lg text-green-700 font-medium flex items-center justify-center bg-green-200 drop-shadow-xl hover:bg-green-300 hover:scale-105 transition-all duration-300"
                            >
                                Our Instagram
                            </a>
                        </div>

                        {/* Band Chat Section */}
                        <div className="flex flex-col items-center w-full md:w-1/2 px-4">
                            <i className='bx bxs-bell-ring text-5xl mb-4'></i>
                            <h3 className="text-2xl font-medium mb-4">Join our Band Chat</h3>
                            <a
                                href="https://band.us/n/a9a9A3O3p7Jci"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-poppins w-56 h-12 rounded-lg text-green-700 font-medium flex items-center justify-center bg-green-200 drop-shadow-xl hover:bg-green-300 hover:scale-105 transition-all duration-300"
                            >
                                Band Chat
                            </a>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </div>
    );
}
