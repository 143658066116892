export default function Footer() {
    return (
        <footer className="bg-white text-tropaz-700 py-6">
            <div className="container mx-auto px-4 text-center">
                {/* Footer Title */}
                <div className="mb-4">
                    <a href="/" className="text-2xl font-bold text-tropaz-700">
                        Wharton FBLA
                    </a>
                </div>

                {/* Navigation Links */}
                <div className="mb-4">
                    <ul className="flex flex-wrap justify-center space-x-6 text-base sm:text-lg">
                        <li>
                            <a href="/about" className="hover:text-orange-300 transition-all duration-300">
                                About
                            </a>
                        </li>
                        <li>
                            <a href="/officers" className="hover:text-orange-300 transition-all duration-300">
                                Officers
                            </a>
                        </li>
                        <li>
                            <a href="/members" className="hover:text-orange-300 transition-all duration-300">
                                Members
                            </a>
                        </li>
                        <li>
                            <a href="/conferences" className="hover:text-orange-300 transition-all duration-300">
                                Conferences
                            </a>
                        </li>
                        <li>
                            <a href="/contact" className="hover:text-orange-300 transition-all duration-300">
                                Contacts
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Social Media Links */}
                <div className="mb-4">
                    <ul className="flex justify-center space-x-6">
                        <li>
                            <a href="https://www.instagram.com/whartonhs.fbla/" className="hover:text-orange-300 transition-all duration-300">
                                <i className="bx bxl-instagram text-5xl"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Copyright Info */}
                <div className="text-sm">
                    <p>&copy; {new Date().getFullYear()} Wharton FBLA. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
