import { useState } from 'react';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <nav className="bg-black bg-opacity-85 shadow-md sticky top-0 z-50 h-24 font-oswald font-extralight uppercase">
            <div className="container mx-auto px-4 py-3 flex justify-between items-center h-full relative">
                <a className="text-lg font-bold" href="/home">
                    <img src="images/logo.png" className="w-16 h-16" alt="Logo" />
                </a>

                <div className="hidden md:flex md:justify-center md:w-full md:space-x-4 text-xl">
                    <a href="/home" className="text-white hover:text-tropaz-700 transition-all duration-300">Home</a>
                    <a href="/about" className="text-white hover:text-tropaz-700 transition-all duration-300">About</a>
                    <a href="/officers" className="text-white hover:text-tropaz-700 transition-all duration-300">Officers</a>
                    <a href="/members" className="text-white hover:text-tropaz-700 transition-all duration-300">Members</a>
                    <a href="/conferences" className="text-white hover:text-tropaz-700 transition-all duration-300">Conferences</a>
                    <a href="/contact" className="text-white hover:text-tropaz-700 transition-all duration-300">Contacts</a>
                </div>

                <button 
                    className={`md:hidden absolute right-4 text-white text-3xl transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                    onClick={toggleMenu}
                >
                    <i className='bx bx-chevron-down'></i>
                </button>
            </div>

            {/* Mobile menu */}
            <div className={`md:hidden transition-all duration-300 ease-in-out ${isOpen ? 'opacity-100 transform translate-y-0 pointer-events-auto' : 'opacity-0 transform -translate-y-4 pointer-events-none'} bg-black bg-opacity-85`}>
                <div className="flex flex-col items-center py-4 space-y-4">
                    <a href="/home" className="text-white text-xl hover:text-tropaz-700 transition-all duration-300" onClick={toggleMenu}>Home</a>
                    <a href="/about" className="text-white text-xl hover:text-tropaz-700 transition-all duration-300" onClick={toggleMenu}>About</a>
                    <a href="/officers" className="text-white text-xl hover:text-tropaz-700 transition-all duration-300" onClick={toggleMenu}>Officers</a>
                    <a href="/members" className="text-white text-xl hover:text-tropaz-700 transition-all duration-300" onClick={toggleMenu}>Members</a>
                    <a href="/conferences" className="text-white text-xl hover:text-tropaz-700 transition-all duration-300" onClick={toggleMenu}>Conferences</a>
                    <a href="/contact" className="text-white text-xl hover:text-tropaz-700 transition-all duration-300" onClick={toggleMenu}>Contacts</a>
                </div>
            </div>
        </nav>
    );
}
