import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Home() {
    return (
        <div className="bg-tropaz-700">
            <Navbar></Navbar>
            <div className="w-full h-[50vh] sm:min-h-screen flex flex-col items-center p-4 bg-tropaz-700">
                <img className="w-48 sm:w-64 md:w-80 lg:w-96" src="images/FBLA.png" alt="FBLA Logo" />
                <h1 className="text-4xl sm:text-6xl md:text-8xl lg:text-9xl text-white font-bold uppercase text-center mt-4">Wharton FBLA</h1>

                <div className="flex flex-col sm:flex-row items-center justify-center w-full sm:w-3/4 lg:w-1/4 h-24 mt-12 space-y-4 sm:space-y-0 sm:space-x-4">
                    <a className="w-32 h-12 rounded-lg text-tropaz-700 font-semibold flex flex-col items-center justify-center bg-white drop-shadow-xl hover:bg-tropaz-800 hover:text-white hover:scale-105 transition-all duration-300" href="/about">About</a>
                    <a className="w-32 h-12 rounded-lg text-tropaz-700 font-semibold flex flex-col items-center justify-center bg-white drop-shadow-xl hover:bg-tropaz-800 hover:text-white hover:scale-105 transition-all duration-300" href="/officers">Officers</a>
                </div>
            </div>

            <div className="w-full bg-white flex flex-col p-4 items-center mt-5">
                <h1 className="text-3xl sm:text-4xl md:text-6xl text-black font-semibold uppercase text-center">What Is FBLA?</h1>

                <div className="w-full sm:w-5/6 lg:w-2/3 flex flex-col lg:flex-row justify-between h-full mt-5">
                    <div className="w-full lg:w-1/2 flex justify-center lg:justify-start items-center p-2 font-light text-xl text-center lg:text-left">
                        <p>
                            The Future Business Leaders of America (FBLA) is a nonprofit educational organization 
                            that prepares students for careers in business and leadership through academic competitions, 
                            leadership development, and community service.
                        </p>
                    </div>

                    <div className="w-full lg:w-1/2 flex justify-center mt-4 lg:mt-0">
                        <img className="w-48 sm:w-64 md:w-80 lg:w-96" src="images/whartonfbla.png" alt="Wharton FBLA" />
                    </div>
                </div>
            </div>

            {/* Updated Section for Cards with Spacing */} 
            <div className="bg-slate-100 w-full py-8 flex flex-col items-center gap-8 px-4 sm:px-8 lg:px-16">
                <div className="flex flex-col lg:flex-row justify-evenly items-center py-2 w-full gap-8">
                    <Card title="About Wharton FBLA" link="/about" image="images/about.png" />
                    <Card title="Wharton FBLA Officers" link="/officers" image="images/officers.png" />
                    <Card title="Members Information" link="/members" image="images/information.png" />
                </div>

                <div className="flex flex-col lg:flex-row justify-evenly items-center py-2 w-full gap-8">
                    <Card title="FBLA Conferences" link="/conferences" image="images/conferences.png" />
                    <Card title="Contact Wharton FBLA" link="/contact" image="images/contacts.png" />
                </div>
            </div>

            <Footer></Footer>
        </div>
    );
}


function Card({ title, link, image }) {
    return (
        <div className="w-full lg:w-[36rem] h-[40rem] bg-tropaz-700 rounded-lg flex flex-col justify-between overflow-hidden drop-shadow-xl">
            <img className="h-[70%] object-cover bg-white" alt={title} src={image} />
            <div className="w-full h-[25%] flex flex-col items-center justify-around p-4">
                <h1 className="text-2xl text-white">{title}</h1>
                <a className="font-poppins w-56 h-12 rounded-lg text-tropaz-700 font-medium flex flex-col items-center justify-center bg-white drop-shadow-xl hover:bg-tropaz-800 hover:text-orange-300 hover:scale-105 transition-all duration-300" href={link}>
                    Learn More
                </a>
            </div>
        </div>
    );
}
