import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Officers from './pages/Officers';
import Members from './pages/Members';
import Contacts from './pages/Contact';
import Conferences from './pages/Conferences';

function App() {
  return (
    <div className='font-poppins'>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/officers" element={<Officers/>}/>
          <Route path="/members" element={<Members/>}/>
          <Route path="/contact" element={<Contacts/>}/>
          <Route path="/conferences" element={<Conferences/>}/>
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
