import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Officers() {
    return (
        <div className="bg-gradient-to-r from-slate-100 to-slate-200 min-h-screen">
            <Navbar />

            <div className="w-full p-4 flex flex-col items-center">
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-light text-tropaz-700 font-oswald uppercase mb-8">
                    Officers
                </h1>

                {/* Team A */}
                <div className="w-full p-4 mb-12 bg-white shadow-lg rounded-lg border-l-4">
                    <h2 className="text-3xl sm:text-4xl font-semibold text-center text-gray-800 mb-8">Team A</h2>

                    {/* Row 1: President, Vice President, Secretary */}
                    <div className="flex flex-col sm:flex-row justify-between gap-6 mb-6">
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/vy.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team A President" />
                            <p className="text-lg sm:text-xl font-medium">Vy Doan - President</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/satya.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team A Vice President" />
                            <p className="text-lg sm:text-xl font-medium">Satya Somalanka - Vice President</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/khushbu.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team A Secretary" />
                            <p className="text-lg sm:text-xl font-medium">Khushbu Patel - Secretary</p>
                        </div>
                    </div>

                    {/* Row 2: Treasurer, Reporter, Historian */}
                    <div className="flex flex-col sm:flex-row justify-between gap-6">
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/fernando.jpg" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team A Treasurer" />
                            <p className="text-lg sm:text-xl font-medium">Fernando Soto-Briceno - Treasurer</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/himani.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team A Reporter" />
                            <p className="text-lg sm:text-xl font-medium">Himani Mehra - Reporter</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/luke.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team A Historian" />
                            <p className="text-lg sm:text-xl font-medium">Luke Dostal - Historian</p>
                        </div>
                    </div>
                </div>

                {/* Team B */}
                <div className="w-full p-4 bg-white shadow-lg rounded-lg border-l-4">
                    <h2 className="text-3xl sm:text-4xl font-semibold text-center text-gray-800 mb-8">Team B</h2>

                    {/* Row 1: President, Vice President, Secretary */}
                    <div className="flex flex-col sm:flex-row justify-between gap-6 mb-6">
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/aarna.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team B President" />
                            <p className="text-lg sm:text-xl font-medium">Aarna Patel - President</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/chavi.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team B Vice President" />
                            <p className="text-lg sm:text-xl font-medium">Chavi Lakhotia - Vice President</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/eric.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team B Secretary" />
                            <p className="text-lg sm:text-xl font-medium">Eric Fernandes - Secretary</p>
                        </div>
                    </div>

                    {/* Row 2: Treasurer, Reporter, Historian */}
                    <div className="flex flex-col sm:flex-row justify-between gap-6">
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/ananda.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team B Treasurer" />
                            <p className="text-lg sm:text-xl font-medium">Ananda Ravinder - Treasurer</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/charlize.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team B Reporter" />
                            <p className="text-lg sm:text-xl font-medium">Charlize Buie - Reporter</p>
                        </div>
                        <div className="flex flex-col items-center w-full sm:w-1/3 transform transition-transform duration-300 hover:scale-105">
                            <img src="images/aayush.png" className="w-32 h-32 rounded-lg mb-4 object-cover shadow-md" alt="Team B Historian" />
                            <p className="text-lg sm:text-xl font-medium">Aayush Iyer - Historian</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
