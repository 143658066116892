import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Members() {
    return (
        <div className="bg-slate-100 min-h-screen flex flex-col">
            <Navbar />

            <main className="flex-grow container mx-auto px-4 py-8">
                <h1 className="text-4xl md:text-6xl lg:text-8xl font-light uppercase font-oswald text-center mb-8 text-tropaz-700">
                    FBLA Competitive Events
                </h1>

                <section className="mb-8 w-full">
                    <div className="flex flex-wrap justify-center gap-4">
                        <div className="w-full sm:w-[22rem] lg:w-[30rem] h-[20rem] p-4 gap-2 flex flex-col items-center justify-evenly text-center bg-tropaz-700 rounded-lg shadow-lg">
                            <h3 className="text-xl md:text-3xl font-bold text-white">Objective Tests</h3>
                            <p className="text-white text-sm md:text-base">
                                Objective tests are multiple-choice exams that assess knowledge in various business topics. They cover subjects like accounting, business law, and economics, testing core concepts within a time limit.
                            </p>
                        </div>

                        <div className="w-full sm:w-[22rem] lg:w-[30rem] h-[20rem] p-4 gap-2 flex flex-col items-center justify-evenly text-center bg-tropaz-700 rounded-lg">
                            <h3 className="text-xl md:text-3xl font-bold text-white">Presentation Events</h3>
                            <p className="text-white text-sm md:text-base">
                                Presentation events involve preparing and delivering a presentation on a business topic. Participants create business plans or digital portfolios, and must effectively communicate and answer questions from judges.
                            </p>
                        </div>

                        <div className="w-full sm:w-[22rem] lg:w-[30rem] h-[20rem] p-4 gap-2 flex flex-col items-center justify-evenly text-center bg-tropaz-700 rounded-lg">
                            <h3 className="text-xl md:text-3xl font-bold text-white">Production Events</h3>
                            <p className="text-white text-sm md:text-base">
                                Production events focus on creating business documents using software like word processors and spreadsheets. Participants produce professional documents within a time limit, demonstrating technical skills.
                            </p>
                        </div>

                        <div className="w-full sm:w-[22rem] lg:w-[30rem] h-[20rem] p-4 gap-2 flex flex-col items-center justify-evenly text-center bg-tropaz-700 rounded-lg">
                            <h3 className="text-xl md:text-3xl font-bold text-white">Role-Play Events</h3>
                            <p className="text-white text-sm md:text-base">
                                Role-play events simulate business scenarios where participants solve problems or develop strategies while interacting with judges acting as clients or managers. Quick thinking and effective communication are key.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="mb-8 w-full">
                    <h2 className="text-3xl md:text-4xl lg:text-5xl text-center mb-4 font-light uppercase font-oswald text-tropaz-700">
                        Objective Tests
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ul className="list-disc list-inside text-gray-700 text-left mx-auto">
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Accounting-I.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Accounting I</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Accounting-II.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Accounting II</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Advertising.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Advertising</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Agribusiness.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Agribusiness</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Business-Communication.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Business Communication</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Business-Law.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Business Law</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Computer-Problem-Solving.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Computer Problem Solving</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Cybersecurity.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Cybersecurity</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Economics.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Economics</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Financial-Math.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Financial Math</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Healthcare-Administration.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Healthcare Administration</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Human-Resource-Management.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Human Resource Management</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Insurance-and-Risk-Management.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Insurance & Risk Management</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Business-Communication.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Business Communication</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Business-Concepts.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Business Concepts</a></li>
                        </ul>
                        <ul className="list-disc list-inside text-gray-700 text-left mx-auto">
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Business-Procedures.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Business Procedures</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Entrepreneurship.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Entrepreneurship</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Financial-Math.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Financial Math</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Information-Technology.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Information Technology</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Management-Concepts.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Management Concepts</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Personal-Finance.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Personal Finance</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Introduction-to-Spreadsheet-Applications.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Spreadsheet Applications</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Middle-School-Computer-Applications.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Middle School Computer Applications</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Middle-School-Introduction-to-Business-Communication.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Middle School Introduction to Business Communication</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Middle-School-Introduction-to-Computer-Science.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Middle School Introduction to Computer Science</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Middle-School-Introduction-to-Personal-Finance.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Middle School Introduction to Personal Finance</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Middle-School-Introduction-to-Spreadsheet-Applications.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Middle School Introduction to Spreadsheet Applications</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Principles-of-Business-Management.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Principles of Business Management</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Principles-of-Finance.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Principles of Finance</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Principles-of-Marketing.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Principles of Marketing</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Spreadsheet-Applications.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Spreadsheet Applications</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Objective%20Tests/Word-Processing.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Word Processing</a></li>
                        </ul>
                    </div>
                </section>

                <section className="mb-8 w-full">
                    <h2 className="text-5xl text-center mb-4 font-light uppercase font-oswald text-tropaz-700">Presentation Events</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ul className="list-disc list-inside text-gray-700 text-left mx-auto">
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Business-Plan.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Business Plan</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Electronic-Career-Portfolio.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Electronic Career Portfolio</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Future-Business-Educator.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Future Business Educator</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Sales-Presentation.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Sales Presentation</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Broadcast-Journalism.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Broadcast Journalism</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Business-Ethics.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Business Ethics</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Coding-and-Programming.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Coding & Programming</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Computer-Game-Simulation-Programming.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Computer Game & Simulation Programming</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Data-Analysis.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Data Analysis</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Digital-Animation.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Digital Animation</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Digital-Video-Production.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Digital Video Production</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Financial-Statement-Analysis.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Financial Statement Analysis</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Graphic-Design.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Graphic Design</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Introduction-to-Business-Presentation.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Business Presentation</a></li>
                        </ul>
                        <ul className="list-disc list-inside text-gray-700 text-left mx-auto">
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Introduction-to-Programming.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Programming</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Introduction-to-Social-Media-Strategy.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Social Media Strategy</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Mobile-Application-Development.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Mobile Application Development</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Public-Service-Announcement.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Public Service Announcement</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Social-Media-Strategies.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Social Media Strategies</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Visual-Design.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Visual Design</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Website-Coding-and-Development.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Website Coding & Development</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Website-Design.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Website Design</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Impromptu-Speaking.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Impromptu Speaking</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Introduction-to-Public-Speaking.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Public Speaking</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Public-Speaking.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Public Speaking</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Future-Business-Leader.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Future Business Leader</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Presentation%20Events/Job-Interview.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Job Interview</a></li>
                        </ul>
                    </div>
                </section>

                <section className="mb-8 w-full">
                    <h2 className="text-5xl text-center mb-4 font-light uppercase font-oswald text-tropaz-700">Production Events</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ul className="list-disc list-inside text-gray-700 text-left mx-auto">
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Production%20Events/Computer-Applications.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Computer Applications</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Production%20Events/Spreadsheet-Applications.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Spreadsheet Applications</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Production%20Events/Word-Processing.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Word Processing</a></li>
                        </ul>
                    </div>
                </section>

                <section className="mb-8 w-full">
                    <h2 className="text-5xl text-center mb-4 font-light uppercase font-oswald text-tropaz-700">Role-Play Events</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ul className="list-disc list-inside text-gray-700 text-left mx-auto">
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Banking-and-Financial-Systems.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Banking & Financial Systems</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Business-Management.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Business Management</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Client-Service.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Client Service</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Entrepreneurship.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Entrepreneurship</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Help-Desk.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Help Desk</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Hospitality-and-Event-Management.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Hospitality & Event Management</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/International-Business.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">International Business</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Introduction-to-Event-Planning.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Introduction to Event Planning</a></li>
                        </ul>
                        <ul className="list-disc list-inside text-gray-700 text-left mx-auto">
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Management-Information-Systems.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Management Information Systems</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Marketing.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Marketing</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Network-Design.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Network Design</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Parliamentary-Procedure.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Parliamentary Procedure</a></li>
                            <li><a href="https://connect.fbla.org/headquarters/files/High%20School%20Competitive%20Events%20Resources/Individual%20Guidelines/Role%20Play%20Events/Sports-and-Entertainment-Management.pdf" className="text-tropaz-700 hover:underline hover:text-orange-300 transition-all duration-300">Sports & Entertainment Management</a></li>
                        </ul>
                    </div>
                </section>

            </main>

            <Footer />
        </div>
    );
}
