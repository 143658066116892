import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function About() {
    return (
        <div className="bg-slate-100">
            <Navbar />

            <div className="w-full px-4 sm:px-6 lg:px-8 py-10 my-10 flex flex-col items-center">
                <div className="w-full lg:w-3/4 xl:w-1/2 flex flex-col items-center p-4 gap-4">
                    <div className="flex flex-col items-center gap-6 text-lg">
                        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-light text-tropaz-700 font-oswald uppercase text-center">
                            About FBLA
                        </h1>
                        <p className="text-tropaz-800 text-lg sm:text-xl text-center">
                            Future Business Leaders of America (FBLA) is a student organization that prepares students 
                            for careers in business and leadership through academic competitions, leadership development, and educational programs. 
                            It is the largest career student business organization in the world, with chapters in middle schools, high schools, and colleges.
                        </p>

                        <p className="text-tropaz-800 text-lg sm:text-xl text-center">
                            FBLA offers various opportunities for students to engage in business-related activities, including competitive events at regional, state, and national levels, 
                            where students can showcase their skills in areas like public speaking, business management, finance, and entrepreneurship. 
                            FBLA also emphasizes community service and networking, helping students build connections and gain real-world experience.
                        </p>

                        <p className="text-tropaz-800 text-lg sm:text-xl text-center">
                            Since its founding in 1940 by Dr. Hamden L. Forkner, FBLA has grown into a premier business student organization. 
                            It has played a crucial role in shaping the lives of millions of students by equipping them with the skills necessary to succeed in the business world. 
                            Many FBLA alumni have gone on to become successful entrepreneurs, executives, and leaders in various industries.
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-full px-4 sm:px-6 lg:px-8 py-8 flex flex-col items-center">
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-light text-tropaz-700 font-oswald uppercase mb-8 text-center">
                    By The Numbers
                </h1>
                <div className="w-full sm:w-4/5 md:w-3/4 lg:w-2/3 bg-white h-auto p-4 mb-10 shadow-lg flex flex-col sm:flex-row justify-evenly">
                    <div className="w-full sm:w-1/2 h-full border-b-2 sm:border-b-0 sm:border-r-2 border-gray-500 flex flex-col items-center justify-evenly mb-4 sm:mb-0">
                        <div className="flex justify-center items-center gap-8 text-4xl sm:text-5xl font-semibold text-orange-300">
                            <i className='bx bxs-user'></i>
                            <h1>45+</h1>
                        </div>

                        <div>
                            <p className="text-lg sm:text-xl font-light text-tropaz-700 font-oswald uppercase mb-5 text-center">
                                Wharton FBLA Members
                            </p>
                        </div>
                    </div>

                    <div className="w-full sm:w-1/2 h-full flex flex-col items-center justify-evenly">
                        <div className="flex justify-center items-center gap-8 text-4xl sm:text-5xl font-semibold text-orange-300">
                            <i className='bx bxs-trophy'></i>
                            <h1>18</h1>
                        </div>

                        <div>
                            <p className="text-lg sm:text-xl font-light text-tropaz-700 font-oswald uppercase mb-5 text-center">
                                Awards Won
                            </p>
                        </div>
                    </div>
                </div>
                <h1 className="text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-light text-tropaz-700 font-oswald uppercase mb-5 text-center">
                    2023-2024 School Year
                </h1>
            </div>

            <Footer />
        </div>
    );
}
