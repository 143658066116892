import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Conferences() {
    return(
        <div className="bg-slate-100">
            <Navbar />

            <main className="w-full my-10 p-4 flex flex-col items-center">
                <section className="text-center mb-16">
                    <h1 className="text-8xl font-light text-tropaz-700 font-oswald uppercase">Coming Soon</h1>
                    <h2 className="text-4xl mt-10 font-medium text-orange-300">Come back when DLC, SLC, or NLC have finished!</h2>
                </section>

                <section className="text-center">
                    <h1 className="text-8xl font-light text-tropaz-700 font-oswald uppercase mb-8">Dates</h1>
                    <div className="text-2xl font-light text-orange-300 font-oswald uppercase">
                        <h3 className="mb-4">Region 8 Conference - The Regent, Riverview, FL: January 28</h3>
                        <h3 className="mb-4">Florida State Leadership Conference - Hilton Orlando, Orlando: March 28 - 31</h3>
                        <h3>National Leadership Conference - Anaheim, CA: June 29 - July 2</h3>
                    </div>
                </section>
            </main>

            <Footer />
        </div>
    );
}
